.cta-big {
    font-weight: var(--fw-medium);
    font-size: var(--fs-h3);
    text-transform: uppercase;
    letter-spacing: .04em;
    border-radius: .6em;
    padding: .4em 1.1em;
}

.cta-shadows {
    box-shadow: 0px 2px 4px 4px var(--color-shadow-dark);
}
  
.cta-accent {
    background-color: var(--color-accent);
    color: var(--color-bg-dark);
    border: 4px solid var(--color-accent);
}
  
.cta-accent:hover,
.cta-accent:focus {
    background: var(--color-bg-darker);
    color: var(--color-bg-light);
    border: 4px solid var(--color-accent);
}