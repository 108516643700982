.contact {
    background: var(--color-bg-dark);
    color: var(--color-bg-dark);
    display: flex;
    align-items: flex-end;
}

.contact__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 1.5em;
}

.contact__card {
    display: flex;
    justify-self: flex-end;
    flex-direction: column;
    margin: 1.5em 0 0;
    width: 100%;
}

.contact__form-container {
    padding: 1.6em 2em 2em;
}

.contact__form-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-bg-light);
    border: 4px solid var(--color-accent);
    border-radius: 1.25em;
}

.contact h1 {
    margin-bottom: 0;
}

.contact__info-container {
    order: 2;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: var(--color-accent);
    border-top-left-radius: 1.25em;
    border-top-right-radius: 1.25em;
    margin-top: 1.5em;
    padding-top: .75em;
    padding-bottom: .75em;
}

.contact__info-text {
    display: none;
    font-family: var(--ff-secondary);
    font-size: var(--fs-h4);
    font-weight: var(--fw-regular);
    max-width: 450px;
    letter-spacing: .03em;
    line-height: 1.7;
    margin: 0;
}

.contact__items {
    font-size: var(--fs-body);
    font-weight: var(--fw-light);
    letter-spacing: .05em;
    padding-bottom: .5em;
}

.contact__socials {
    margin-top: 1.5em;
}

.contact__socials .social-icon__link {
    margin-right: 1.75em;
}

@media (min-width: 680px) {
    .contact__form-container {
        padding-left: 3em;
        padding-right: 3em;
        padding-bottom: 2.5em;
    }
}

@media(min-width: 820px) {
    .contact {
        align-items: center;
    }

    .contact__container {
        padding-bottom: 1.5em;
    }

    .contact__card {
        flex-direction: row;
        margin: 3em 0;
    }

    .contact__form-container, 
    .contact__info-container {
        padding: 1.6em 2em 2em;
    }

    .contact__info-container {
        order: 1;
        border-top-right-radius: 0;
        border-top-left-radius: 1.25em;
        border-bottom-left-radius: 1.25em;
        text-align: left;
        align-items: flex-start;
        color: var(--color-bg-dark);
        margin-top: 0;
    }
    
    .contact__form-container {
        order: 2;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .contact__info-text {
        display: initial;
    }

    .contact__items {
        font-size: var(--fs-card);
    }

    .contact__socials {
        margin-top: 0;
    }
    
    .contact__socials .social-icon__link {
        margin-right: 1.25em;
    }
}

@media (min-width: 1120px) {
    .contact__form-container, 
    .contact__info-container {
        padding: 2.4em 2.8em 2.8em;
    }

    .contact__info-text {
        font-size: calc(var(--fs-h3) - .15rem);
    }
}