.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-form__row {
    display: flex;
    flex-direction: column;
}

.contact-form__input-container {
    margin-bottom: 3.25em;
}

.contact-form__textarea-container {
    margin-bottom: 1em;
}

.contact-form__required-label::after {
    content: " *";
    color: var(--color-accent);
}

.contact-form label {
    letter-spacing: .04em;
    margin-bottom: .8em;
}

.contact-form input, .contact-form textarea {
    outline: none;
    border-width: 0 0 2px;
    border-color: var(--color-bg-dark);
    background: none;
    color: var(--color-bg-dark);
    font-weight: var(--fw-light);
    letter-spacing: .03em;
    padding-bottom: .4em;
}

.contact-form input, 
.contact-form textarea,
.contact-form label {
    font-size: var(--fs-body);
}

.contact-form input:focus, .contact-form textarea:focus {
    border-color: var(--color-accent);
}

.contact-form input::placeholder, .contact-form textarea::placeholder {
    color: var(--color-bg-dark);
    opacity: .65;
    font-weight: var(--fw-light);
    font-size: var(--fs-card);
}

.contact-form input {
    width: 100%;
}

.contact-form__btn {
    align-self: center;
    margin-top: 1.75em;
    background-color: var(--color-accent);
    color: var(--color-bg-dark);
    border: 4px solid var(--color-bg-dark);
    font-weight: var(--fw-medium);
    font-size: var(--fs-body);
    text-transform: uppercase;
    letter-spacing: .04em;
    border-radius: .6em;
    padding: .4em 1em;
}

.contact-form__btn:hover,
.contact-form__btn:focus {
    background: var(--color-bg-darker);
    color: var(--color-bg-light);
    border: 4px solid var(--color-accent);
}

.success-msg {
    background: var(--color-bg-dark);
}

.error-msg {
    background: var(--color-error);
}

@media (min-width: 540px) {
    .contact-form input, 
    .contact-form textarea,
    .contact-form label {
        font-size: var(--fs-card);
    }

    .contact-form input::placeholder, .contact-form textarea::placeholder {
        font-size: var(--fs-body);
    }
}

@media(min-width: 820px) {
    .contact-form__row {
        flex-direction: row;
        justify-content: space-between;
    }

    .contact-form__input-container {
        min-width: 40%;
        max-width: 45%;
    }

    .contact-form__btn {
        font-size: var(--fs-h4);
    }
}

@media (min-width: 1120px) {
    .contact-form {
        padding: 0 1em;
    }

    .contact-form input::placeholder, .contact-form textarea::placeholder {
        font-size: inherit;
    }
}
