.skills {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-bg-darker);
    text-align: center;
}

.skills__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skills__description {
    font-family: var(--ff-secondary);
    font-size: var(--fs-h3);
    letter-spacing: .03em;
    line-height: 1.6;
    margin-top: 1.2em;
    max-width: 450px;
}

.skills__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: .6em 15%;
    list-style-image: url('../../assets/bullet.png');
    list-style-position: inside;
    font-size: var(--fs-h3);
    font-weight: var(--fw-regular);
    letter-spacing: .06em;
    text-align: left;
    margin-bottom: 1em;
    margin-left: .1em;
    align-self: center;
}

.skills__list-item-text {
    position: relative;
    left: .5em;
}

.skills__left {
    display: none;
}

.skills__right {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

@media(min-width: 540px) {
    .skills__list {
        max-width: 450px;
        width: 100%;
    }
}

@media(min-width: 870px) {
    .skills {
        position: relative;
        background: linear-gradient(to left, var(--color-bg-darker) 62%, var(--color-accent) 38%);
        text-align: left;
    }

    .skills__left, 
    .skills__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }

    .skills__right {
        width: 100%;
    }

    .skills__left {
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 38%;
    }

    .skills__info {
        display: initial;
        overflow: hidden;
        padding-left: 43%;
    }

    .skills__cards {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        height: 70%;
        width: 115%;
    }

    .skills__description {
        max-width: initial;
    }

    .skills__list {
        max-width: initial;
    }

    .skills__list-item-text {
        left: .9em;
    }
}

@media (min-width: 1120px) {
    .skills {
        background: linear-gradient(to left, var(--color-bg-darker) 67%, var(--color-accent) 33%);
    }

    .skills__left {
        width: 33%;
    }

    .skills__description:first-of-type {
        margin-top: 1.8em;
    }    

    .skills__description:last-of-type {
        margin-bottom: 2em;
    }

    .skills__info {
        padding-left: 39%;
    }

    .skills__cards {
        width: 110%;
    }

    .skills__cards .card:nth-child(2) {
        align-self: flex-start;
    }
}

@media (min-width: 1920px) {
    .skills__cards .card:nth-child(2) {
        align-self: flex-end;
    }
}